<script setup lang="ts">
import { entityTypesStore } from '~/stores/entity_types'
import { getBackgroundColorForEntityTypeColor } from '~/utils/entityTypeColors'

const props = defineProps<{
  displayName: string | undefined
  icon?: string | null
  color?: string | null
}>()

const icon = computed(() => {
  return props?.icon || 'carbon-cube'
})

const color = computed(() => {
  return props?.color || '#000000'
})

const displayName = computed(() => {
  const entityTypes = entityTypesStore().getEntityTypesAsOptionsWithNameAsValue
  const matchedEntity = entityTypes.find(e => e.name === props.displayName)
  return matchedEntity?.display_name || props.displayName
})
</script>

<template>
  <div
    class="flex items-center h-fit gap-1.25 py-0.75 px-1.25 text-sm rounded-md"
    :style="{ color: color || '#000000', backgroundColor: getBackgroundColorForEntityTypeColor(color) }"
  >
    <div :i="icon" class="w-3.5 h-3.5" />
    <p class="line-clamp-1 max-w-[150px]">{{ displayName || 'Unknown' }}</p>
  </div>
</template>
