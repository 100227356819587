import { getLocation } from './utils'

export enum Tenants {
  DEFAULT = 'default',
  FORVIA = 'forvia',
  AURA_AERO = 'aura-aero',
}

export enum Stages {
  LOCAL = 'local',
  DEV = 'dev',
  STAGING = 'staging',
  PROD = 'prod',
}

export enum Services {
  CLIENT = 'client',
  API = 'api',
}

function getTenantPrefix(tenant: Tenants, stage: Stages): string {
  if (tenant === Tenants.DEFAULT) {
    if (stage === Stages.PROD && isRunningOnAzure()) {
      return 'app'
    }
    return ''
  }

  const suffix = stage === Stages.PROD && isRunningOnAzure() ? '' : '-'
  return `${tenant.toString()}${suffix}`
}

function getStagePrefix(stage: Stages, service: Services): string {
  if (stage === Stages.PROD) {
    return service === Services.CLIENT ? 'app' : ''
  }

  if (stage === Stages.LOCAL && service === Services.API) {
    return 'dev-'
  }

  const suffix = isRunningOnAzure() && service === Services.API ? '' : '-'

  return `${stage.toString()}${suffix}`
}

type Environment = `${Tenants}.${Stages}`

function parseTenantFromHostname(): Tenants {
  const { hostname } = getLocation()

  for (const tenant of Object.values(Tenants)) {
    if (hostname.includes(tenant)) {
      return tenant
    }
  }

  return Tenants.DEFAULT
}

function parseStageFromHostname(): Stages {
  const { hostname } = getLocation()

  if (hostname.includes('localhost')) {
    return Stages.LOCAL
  }

  for (const stage of Object.values(Stages)) {
    if (hostname.includes(stage)) {
      return stage
    }
  }

  return Stages.PROD
}

function getTenant(): Tenants {
  const tenantEnvVar = import.meta.env.VITE_TENANT

  if (tenantEnvVar && Object.values(Tenants).includes(tenantEnvVar as Tenants)) {
    return tenantEnvVar as Tenants
  }

  return parseTenantFromHostname()
}

function getStage(): Stages {
  const stageEnvVar = import.meta.env.VITE_STAGE

  if (stageEnvVar && Object.values(Stages).includes(stageEnvVar as Stages)) {
    return stageEnvVar as Stages
  }

  return parseStageFromHostname()
}

export function getEnvironment(): Environment {
  const tenant = getTenant()
  const stage = getStage()

  if (tenant && stage) {
    return `${tenant}.${stage}`
  }

  throw new Error('Could not determine environment')
}

export function getServiceUrl(service: Services): string {
  const stage = getStage()
  const localServiceUrl = import.meta.env[`VITE_${service.toUpperCase()}_URL`]

  if (localServiceUrl && stage === Stages.LOCAL) {
    return localServiceUrl
  }

  const tenant = getTenant()

  return `https://${getTenantPrefix(tenant, stage)}${getStagePrefix(stage, service)}${servicesURLMap[service]}`
}

function isRunningOnAzure() {
  return import.meta.env.VITE_CLOUD_PROVIDER === 'azure'
}

const servicesURLMap: Record<Services, string> = {
  [Services.CLIENT]: '.cognyx.io',
  get [Services.API]() {
    if (isRunningOnAzure()) {
      return '.cognyx.io'
    }

    return 'api.cognyx.io'
  },
}
