<script setup lang="ts">
import { reactive } from 'vue'
import { unwrapRouteParam } from '~/utils/route'

const props = defineProps<{
  title?: string
  tag?: string
  icon?: string
  tabs?: { id: string; label: string }[]
  tabName?: string
  noPadding?: boolean
}>()

const route = useRoute()
const initialTab = unwrapRouteParam(route.query[props.tabName ?? 'tab'])

const state = reactive<{ selectedTab: number }>({
  selectedTab: initialTab ? Number(initialTab) : 0,
})
</script>

<template>
  <div class="pt-4 bg-gray-50 h-full flex flex-col">
    <PageHeader
      :title="title"
      :tag="tag"
      :icon="icon"
      :tabs="tabs?.map(tab => tab.label)"
      :tab-name="tabName"
      v-model="state.selectedTab"
    >
      <template v-for="(_, slotName) in $slots" v-slot:[slotName]="slotProps">
        <slot :name="slotName" v-bind="slotProps ?? {}" />
      </template>
    </PageHeader>

    <div class="h-full overflow-auto flex flex-col gap-8 bg-white" :class="[noPadding ? 'p-0' : 'px-6 pt-8']">
      <template v-if="tabs">
        <slot :name="tabs[state.selectedTab].id"></slot>
      </template>
      <template v-else>
        <slot></slot>
      </template>
    </div>
  </div>
</template>
