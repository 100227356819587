import { FontWeight, TextSize } from './types'

export function getLineHeight(textSize: TextSize) {
  switch (textSize) {
    case TextSize.xxs:
      return '18px'
    case TextSize.xs:
      return '20px'
    case TextSize.s:
      return '22px'
    case TextSize.m:
      return '24px'
    case TextSize.l:
      return '28px'
    case TextSize.xl:
      return '32px'
    case TextSize.xxl:
      return '38px'
    case TextSize.xxxl:
      return '46px'
    case TextSize.xxxxl:
      return '54px'
    case TextSize.xxxxxl:
      return '64px'
  }
}

export function mapSize(size?: string): TextSize {
  switch (size) {
    case 'xxs':
      return TextSize.xxs
    case 'xs':
      return TextSize.xs
    case 's':
      return TextSize.s
    case 'm':
      return TextSize.m
    case 'l':
      return TextSize.l
    case 'xl':
      return TextSize.xl
    case 'xxl':
      return TextSize.xxl
    case '3xl':
      return TextSize.xxxl
    case '4xl':
      return TextSize.xxxxl
    case '5xl':
      return TextSize.xxxxxl
    default:
      return TextSize.m
  }
}

export function mapWeight(weight?: string): FontWeight {
  switch (weight) {
    case 'regular':
      return FontWeight.regular
    case 'bold':
      return FontWeight.bold
    case 'semibold':
      return FontWeight.semibold
    default:
      return FontWeight.regular
  }
}

export function capitalize(string: string) {
  return string.charAt(0).toUpperCase() + string.slice(1)
}
