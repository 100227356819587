import { intersection } from 'lodash'
import { defineStore } from 'pinia'
import { apiStore } from '~/stores/api'
import { User, UserRoles } from '~/types/user'

export const userStore = defineStore<
  'user',
  {
    user: User | null
    stores_loaded: boolean
  },
  {
    getUser(): User | null
    getFullName(): string
  },
  {
    loadUser(): Promise<{ error: true; user: null } | { error: false; user: User }>
    userHasRole(...role: UserRoles[]): boolean
    setStoresLoadingState(state?: boolean): void
  }
>({
  // name of the store
  // it is used in devtools and allows restoring state
  id: 'user',
  // a function that returns a fresh state
  state: () => ({
    user: null,
    stores_loaded: false,
  }),

  // optional getters
  getters: {
    getUser(): User | null {
      return this.user
    },
    getFullName(): string {
      return `${this.user?.name || ''}`
    },
  },
  // optional actions
  actions: {
    setStoresLoadingState(state: boolean = true) {
      this.stores_loaded = state
    },
    userHasRole(...roles: UserRoles[]): boolean {
      if (!this.user) {
        throw new Error('User not loaded')
      }

      return (
        intersection(
          this.user.roles,
          roles.map(role => role.toString()),
        ).length > 0
      )
    },
    async loadUser(): Promise<{ error: true; user: null } | { error: false; user: User }> {
      const api = apiStore().getApiClient
      const result: { error: boolean; user: null | User } = {
        error: false,
        user: null,
      }

      const currentUser = await api.getLoggedInUserInformation()
      if (currentUser?.error) {
        result.error = true
        return result as { error: true; user: null }
      }
      this.user = currentUser.data

      if (!this.user) {
        result.error = true
        return result as { error: true; user: null }
      }

      result.user = this.user
      const splitName = this.user.name.split(' ')
      this.user.first_name = splitName[0]
      this.user.last_name = splitName.slice(1).join(' ')

      return result as { error: false; user: User }
    },
  },
})
