<script setup lang="ts">
import { type ChartData, type ChartOptions, type ChartTypeRegistry } from 'chart.js'
import { v4 as uuid } from 'uuid'
import Chart from 'chart.js/auto'

/**
 * @type : String
 * (The type of the chart : https://www.chartjs.org/docs/latest/ => Chart Types)
 *
 * @data : ChartData
 * (The data of the chart, be carefull of your datasets format matching the type of the chart)
 *
 * @options : Object
 * (https://www.chartjs.org/docs/latest/general/options.html)
 *
 * @plugins : Object
 * (https://www.chartjs.org/docs/latest/developers/plugins.html)
 */
const props = defineProps({
  type: {
    type: String as () => keyof ChartTypeRegistry,
    required: true,
  },
  data: {
    type: Object as PropType<ChartData>,
    required: true,
  },
  options: {
    type: Object as PropType<ChartOptions>,
    required: false,
    default: () => ({}),
  },
  plugins: {
    type: Object,
    required: false,
    default: () => ({}),
  },
})

const emit = defineEmits<{
  (e: 'on-label-click', data: any, label: object): void
}>()

const ChartTypesEnum: Record<string, string> = {
  Bar: 'bar',
  Line: 'line',
  Pie: 'pie',
  Doughnut: 'doughnut',
  Radar: 'radar',
  PolarArea: 'polarArea',
  Bubble: 'bubble',
}

const state = reactive({
  id: uuid(),
  defaultData: {
    labels: ['default label 1', 'default label 2', 'default label 3'],
    datasets: [
      {
        label: 'dataset default',
        data: [1, 2, 3],
      },
    ],
  },
  options: {
    scales: {
      y: {
        beginAtZero: false,
        ticks: {
          stepSize: 1,
        },
      },
    },
  } as any,
})

function generateData() {
  return props.data ?? state.defaultData
}

function generateOptions() {
  if (props.options) return props.options

  // generate default options for each type in ChartTypesEnum
  switch (props.type) {
    case ChartTypesEnum.Bar:
    case ChartTypesEnum.Line:
    case ChartTypesEnum.Bubble:
      state.options.scales.y.beginAtZero = true
      break

    case ChartTypesEnum.Pie:
    case ChartTypesEnum.Doughnut:
    case ChartTypesEnum.Radar:
    case ChartTypesEnum.PolarArea:
    default:
      state.options = {}
      break
  }

  return state.options
}

function generatePlugins() {
  return props.plugins ? [props.plugins] : []
}

function loadChart() {
  const canvas: any = document.getElementById(state.id)
  const ctx = canvas.getContext('2d')

  const chart = new Chart(ctx, {
    type: props.type,
    data: generateData(),
    options: generateOptions(),
    plugins: generatePlugins(),
  })

  canvas.onclick = (evt: any) => {
    const res = chart.getElementsAtEventForMode(evt, 'nearest', { intersect: true }, true)

    if (res.length === 0) return

    emit(
      'on-label-click',
      res[0],
      chart.data.labels?.[res[0].index] || chart.data.datasets?.[res[0].datasetIndex].label || {},
    )
  }
}

onMounted(() => {
  loadChart()
})
</script>

<template>
  <div class="flex h-full w-full">
    <canvas :id="state.id" />
  </div>
</template>
