import { getEnv } from '~/config'

export function usePlaygroundMountHook() {
  const router = useRouter()

  onBeforeMount(() => {
    const env = getEnv()

    if (!['dev', 'local'].includes(env)) {
      router.replace('/')
    }
  })
}
