import { getEnvironment, getServiceUrl, Services } from './environments'

export const FIREBASE_CONFIG_STAGING = {
  apiKey: 'AIzaSyAE8nXZUwNfZuS3XaNMX4Iz40XRi3pxVxs',
  authDomain: 'bomx-staging.firebaseapp.com',
  projectId: 'bomx-staging',
  storageBucket: 'bomx-staging.appspot.com',
  messagingSenderId: '308821636105',
  appId: '1:308821636105:web:6f31a766390c06ba52b4d8',
}

export const FIREBASE_CONFIG_PRODUCTION = {
  apiKey: 'AIzaSyACaCuL2rA4VtDreqYsRg8o74GeVrwT2AM',
  authDomain: 'cognyx-prod-c1345.firebaseapp.com',
  projectId: 'cognyx-prod-c1345',
  storageBucket: 'cognyx-prod-c1345.appspot.com',
  messagingSenderId: '514177689137',
  appId: '1:514177689137:web:b28b427306642e98a19710',
}

export const PUSHER_API_KEY = 'f6127c17410b0ac37c80'
export const GROWTHBOOK_API_KEY_DEV = 'sdk-0dnTcu05PB0jApQ'
export const GROWTHBOOK_API_KEY_PROD = 'sdk-23lm4pUs4JhWjQPp'

// Please toggle the lines bellow to switch between environments, do not overwrite
// export const BACKEND_URL_PHP_DEV = 'http://cognyx-backend.test';
// export const BACKEND_URL_PHP_DEV = 'http://127.0.0.1:8000';
// export const BACKEND_URL_PHP_DEV = 'https://dev-api.cognyx.io'
//export const BACKEND_URL_PHP_DEV = 'https://ancient-valley-zuq2vezdcsk9.vapor-farm-e1.com'; // bom instances
// Please toggle the lines above to switch between environments, do not overwrite

export const SENTRY_DSN =
  'https://36e49110207aae7da2e136bdcce44f3a@o4507096397316096.ingest.de.sentry.io/4507100703555664'

export function getEnv() {
  const env = getEnvironment()
  switch (env) {
    case 'forvia.dev':
      return 'forvia-dev'
    case 'forvia.staging':
      return 'forvia-staging'
    case 'forvia.prod':
      return 'forvia-prod'

    case 'aura-aero.staging':
      return 'aura-aero-staging'
    case 'aura-aero.prod':
      return 'aura-aero-prod'

    case 'default.staging':
      return 'staging'
    case 'default.prod':
      return 'prod'

    case 'default.dev':
      return 'dev'

    case 'default.local':
      return 'local'

    default:
      return env
  }
}

export function getBackEndPhpURL() {
  return getServiceUrl(Services.API)
}

export function getFirebaseConfig() {
  const environment = getEnvironment()

  return environment.includes('prod') ? FIREBASE_CONFIG_PRODUCTION : FIREBASE_CONFIG_STAGING
}

export function getPusherAppKey() {
  const environment = getEnvironment()

  switch (environment) {
    case 'forvia.dev':
      return 'dcf210f3b0a7b9578b9a'
    case 'forvia.staging':
      return 'dcf210f3b0a7b9578b9a'
    case 'forvia.prod':
      return 'd728f9515a8d2a748491'

    case 'aura-aero.staging':
      return 'b0113cc2a60df53b5500'

    case 'aura-aero.prod':
      return '99c0a2bd2e1b357cc083'

    case 'default.staging':
      return '31108627f38bd6d281a0'
    case 'default.prod':
      return 'd974722c4a70f497be08'

    default:
      return 'f6127c17410b0ac37c80'
  }
}

export const SUPPLIER_RELATIONSHIPS = [
  {
    label: 'Can be supplied by',
    value: 'CAN_BE_SUPPLIED_BY',
  },
  {
    label: 'Is supplied by',
    value: 'IS_SUPPLIED_BY',
  },
]

export const DEFAULT_SUPPLIER_RELATIONSHIP = 'CAN_BE_SUPPLIED_BY'
