import Echo from 'laravel-echo'
import { PusherChannel } from 'laravel-echo/dist/channel'
import { defineStore } from 'pinia'
import Pusher from 'pusher-js'
import { getSocketConfig } from '~/config/sockets'
import ApiHelper from '~/helpers/ApiHelper'
import { BroadcastAuthResponse } from '~/types/auth'
import { Callback } from '../types/utility'

interface State {
  _echo: Echo | null
  _pusher: Pusher | null
  _api: ApiHelper | null
}

export const useSockets = defineStore({
  id: 'sockets',
  state: (): State => ({
    _echo: null,
    _pusher: null,
    _api: null,
  }),
  actions: {
    async getClient() {
      if (!window.Pusher) {
        window.Pusher = Pusher
      }

      if (!this._echo) {
        const apiClient = this._api ?? new ApiHelper()

        if (!this._api) {
          await apiClient.getToken()
          this._api = apiClient
        }

        this._echo = new Echo(
          getSocketConfig((channel: PusherChannel) => ({
            authorize: this.authorize(channel.name, apiClient),
          })),
        )
      }

      return this._echo
    },

    authorize(name: string, apiClient: ApiHelper) {
      return async (socketId: string, callback: Callback<BroadcastAuthResponse>) => {
        const payload = {
          socket_id: socketId,
          channel_name: name,
        }

        try {
          const response = await apiClient.getBroadcastingAuth(payload)
          callback(null, response)
        } catch (e) {
          callback(e)
        }
      }
    },
  },
})
