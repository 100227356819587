import { LocationQueryValue } from 'vue-router'

export function getQueryParams(): URLSearchParams {
  const search = window.location.search
  const params = new URLSearchParams(search)
  return params
}

export function getQueryParam(key: string): string {
  const params = getQueryParams()
  const value = params.get(key) || ''
  return value
}

export function unwrapRouteParam(param: LocationQueryValue | LocationQueryValue[] | undefined): string {
  const value = Array.isArray(param) ? param[0] : param

  return value ? `${value}` : ''
}
