export enum TextSize {
  xxs = 'xxs',
  xs = 'xs',
  s = 's',
  m = 'm',
  l = 'l',
  xl = 'xl',
  xxl = 'xxl',
  xxxl = '3xl',
  xxxxl = '4xl',
  xxxxxl = '5xl',
}

export enum FontWeight {
  regular = 'normal',
  semibold = 'semibold',
  bold = 'bold',
}
